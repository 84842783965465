<template>
	<div v-if="userTests && testScores && testBanks">
		<TestFilterTags
			class="mb-8"
			:filterTestStatus="filterTestStatus"
			:toogleFilterTestStatus="toogleFilterTestStatus"
		/>
		<!-- NO TEST ASSIGNED -->
		<div v-if="userTests && userTests.length === 0" class="no-test-assigned">
			<img src="@/assets/images/not-test-assigned.webp" />
			<p>Tu n’as pas encore de défis</p>
		</div>
		<!-- SEARCH FOUND NO TEST -->
		<div v-else-if="filteredTests && filteredTests.length === 0" class="no-test-assigned">
			<img src="@/assets/images/search-test-no-result.webp" />
			<p>Bravo ! Tous tes défis ont été complétés.</p>
			<p class="text-grey">Sélectionne plus de filtres pour afficher tes défis déjà réalisés.</p>
		</div>
		<div class="tests-list" v-else-if="filteredTests && filteredTests.length > 0">
			<TestCard
				v-for="test in orderTestsInStatus(filteredTests)"
				:key="test._id"
				:test="test"
				:userId="userId"
				:testBank="findTestBank(test.test_bank_id)"
				:testScores="findTestScoresByTestId(testScores, test._id)"
				:topicSlugQuery="topicSlugQuery"
			/>
		</div>
	</div>
</template>

<script>
import { TEST_STATUS } from '@/constants/test.js';
import { decideTestStatus } from '@/utils/test.js';
import TestFilterTags from '@/components/test-filter-tags/TestFilterTags.vue';
import TestCard from '@/components/test-cards/TestCard.vue';
import { getLastTestScore } from '@/utils/testScore.js';

export default {
	name: 'UserTestsList',
	components: { TestFilterTags, TestCard },
	props: {
		userId: String,
		topicSlugQuery: String,
	},
	data() {
		return {
			userTests: null, // Not include 'canceled' tests and have 'status'
			filteredTests: null,
			testScores: null,
			testBanks: null,
			filterTestStatus: {
				inProgress: true,
				scheduled: true,
				completed: false,
				notComplete: false,
			},
		};
	},
	async created() {
		let _userTests = await this.$store.dispatch('tests/fetchAllUserTests', { userId: this.userId });
		_userTests = _userTests.filter((test) => test.canceled_at == null);
		const [testBanks, topics, testScores] = await Promise.all([
			this.$store.dispatch('tests/searchTestBanks', {
				testBankIds: _userTests.map((test) => test.test_bank_id),
			}),
			this.$store.dispatch('topics/fetchAllTopics'),
			this.$store.dispatch('testScores/fetchTestScores', { userId: this.userId }),
		]);

		/** Filter tests by topicSlugQuery. If topicSlugQuery == null show all tests*/
		if (this.topicSlugQuery) {
			const topic = topics.find((topic) => topic.slug === this.topicSlugQuery);
			const testBanksOfTopic = testBanks.filter((testBank) => testBank.topic_id === topic._id);
			_userTests = _userTests.filter((test) => testBanksOfTopic.some((testBank) => testBank._id === test.test_bank_id));
		}

		// Find status for _userTests
		_userTests.forEach((test) => {
			const _testScores = this.findTestScoresByTestId(testScores, test._id);
			const statusOfTheTest = decideTestStatus(test, _testScores);
			test.status = statusOfTheTest;
		});

		this.$store.dispatch('tests/setAllTestsOfUserAction', _userTests);
		this.userTests = _userTests;

		this.testScores = testScores;
		this.testBanks = testBanks;
		this.filteredTests = this.filterTests(this.userTests);
	},
	methods: {
		findTestBank(testBankId) {
			if (testBankId == null) return null;
			if (this.testBanks == null) return null;
			return this.testBanks.find((testBank) => testBank._id === testBankId);
		},
		findTestScoresByTestId(testScores, testId) {
			if (testId == null) return null;
			if (testScores == null) return null;
			return testScores.filter((testScore) => testScore.test_id === testId);
		},
		toogleFilterTestStatus(name) {
			this.filterTestStatus[name] = !this.filterTestStatus[name];
		},
		orderCompletedTest(testsHaveLastAnsweredTime) {
			testsHaveLastAnsweredTime.forEach((test) => {
				const testScoresOfThisTest = this.findTestScoresByTestId(this.testScores, test._id);
				const lastTestScore = getLastTestScore(testScoresOfThisTest);
				if (lastTestScore) test.last_answered_time = lastTestScore.last_answered_time;
			});

			testsHaveLastAnsweredTime.sort(
				(a, b) => new Date(b.last_answered_time).getTime() - new Date(a.last_answered_time).getTime()
			);

			return testsHaveLastAnsweredTime;
		},
		orderTestsInStatus(testsWithStatus) {
			const inProgressTests = testsWithStatus.filter((test) => test.status === TEST_STATUS.inProgress);

			const scheduledTests = testsWithStatus.filter((test) => test.status === TEST_STATUS.scheduled);
			scheduledTests.sort((a, b) => new Date(a.begin_date).getTime() - new Date(b.begin_date).getTime());

			const notCompletedTests = testsWithStatus.filter((test) => test.status === TEST_STATUS.notComplete);

			const completedTests = testsWithStatus.filter((test) => test.status === TEST_STATUS.completed);
			const sortedCompletedTests = this.orderCompletedTest(completedTests);

			return [...inProgressTests, ...scheduledTests, ...sortedCompletedTests, ...notCompletedTests];
		},
		filterTests(tests) {
			let inProgressTests = [];
			let scheduledTests = [];
			let completedTests = [];
			let notCompletedTests = [];

			if (this.filterTestStatus.inProgress) {
				inProgressTests = tests.filter((test) => test.status === TEST_STATUS.inProgress);
			}
			if (this.filterTestStatus.scheduled) {
				scheduledTests = tests.filter((test) => test.status === TEST_STATUS.scheduled);
			}
			if (this.filterTestStatus.notComplete) {
				notCompletedTests = tests.filter((test) => test.status === TEST_STATUS.notComplete);
			}
			if (this.filterTestStatus.completed) {
				completedTests = tests.filter((test) => test.status === TEST_STATUS.completed);
			}

			return [...inProgressTests, ...scheduledTests, ...completedTests, ...notCompletedTests];
		},
	},
	watch: {
		filterTestStatus: {
			handler() {
				this.filteredTests = this.filterTests(this.userTests);
			},
			deep: true,
		},
	},
};
</script>

<style scoped lang="scss">
.tests-list {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	justify-content: center;
	gap: 20px;
}

.no-test-assigned {
	text-align: center;
	img {
		width: 30%;
	}
	p {
		font-size: 17px;
	}
}

@media (min-width: 600px) and (max-width: 899px) {
	.tests-list {
		grid-template-columns: repeat(2, 1fr);
		gap: 20px;
	}
	.no-test-assigned {
		img {
			width: 50%;
		}
	}
}

@media (max-width: 599px) {
	.tests-list {
		grid-template-columns: 1fr;
		gap: 20px;
	}
	.no-test-assigned {
		img {
			width: 80%;
		}
	}
}
</style>
